@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap');

@layer base {
  @font-face {
    font-family: "DMSans-Regular";
    font-style: normal;
    font-display: swap;
    src: url("/public/fonts/DMSans-Regular.ttf") format("truetype");
  }
}

@font-face{
  src: url("/public/fonts/AvenirNextLTPro-Regular.otf") format('opentype');
  font-family: "AvenirNextLTPro";
  font-display: swap;
}

/* @font-face {
 font-family: 'DMSans-Regular';
 src: url('/public/fonts/DMSans-Regular.ttf') format('truetype');
 font-display: swap;
} */

@font-face {
 font-family: 'DMSans-Medium';
 src: url('/public/fonts/DMSans-Medium.ttf') format('truetype');
 font-display: swap;
}

@font-face {
 font-family: 'DMSans-Bold';
 src: url('/public/fonts/DMSans-Bold.ttf') format('truetype');
 font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('/public/fonts/Montserrat-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('/public/fonts/Montserrat-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('/public/fonts/Montserrat-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('/public/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-display: swap;
}



html {
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: 'DMSans-Regular'; 
  outline: none;
  /* cursor: url('/src/Media/curs.svg') , auto; */
}

.zindexAll {
  z-index: 1000;
}


.no-scrollbar-div {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.no-scrollbar-div::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}


* {
  cursor: url('/src/Media/curs.svg') , auto;
}

.cursorpointer, .cursorpointer *:hover {
  cursor: url('/src/Media/curs2.svg'), auto; 
}

.cursorpointer-green, .cursorpointer-green *:hover {
  cursor: url('/src/Media/curs.svg'), auto; 
}


.cursorpointer *:hover::selection {
  cursor: url('/src/Media/curs2.svg') , auto;
}

a:hover::selection ,a *:hover::selection {
  background-color: #2575F0 !important; 
}

.textErrorSelection::selection {
  background-color: #2575F0; 
}

/* a *:hover::selection {
  background-color: #2575F0 !important; 
} */

.animationBtn {
  animation-timing-function: ease-out;
  animation-duration: 60ms;
}

.animation {
  animation: element-animation 400ms;
  transition: 0.3s ease;
}

.animation:nth-child(even) {
  animation: element-animation-even 400ms;
}

/*Sidebar*/
.hover-active-color:hover>* , .hover-active-color:hover>*>*{
  color: #3aada1 !important; /* Teal color on hover */
}

.active-icon-color , .active-icon-color>* {
  color: #3aada1 !important; /* Teal color on active */
}

/*Select*/
.hover-select-color:hover>* , .hover-select-color:hover>*>*{
  color: #35D8BF !important; /* Teal color on hover */
}

.hover-select-color:hover * {
  color: #35D8BF !important;
}

.select-color-text * {
  color: #35D8BF !important;
}

.icon-container:hover svg>path {
  stroke: #FFFFFF;
}

.icon-container1:hover svg>path {
  stroke: #2575F0;
}

@keyframes element-animation {
  0% {
      opacity: 0;
      transform: translateX(-10px);
  }

  100% {
      opacity: 1;
      transform: none;
  }
}

@keyframes element-animation-even {
  0% {
      opacity: 0;
      transform: translateX(10px);
  }

  100% {
      opacity: 1;
      transform: none;
  }
}


::-webkit-scrollbar {
  width: 5px;
  height : 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;

}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 5px;
  background: #E4E7EC;
  border-radius: 10px;
}

/*capitalize first letter*/
.capitalize-first::first-letter {
  text-transform: uppercase;
}

/* Selection*/

::selection {
  background-color: #00CDAE; 
  cursor: url('/src/Media/curs2.svg') , auto;
}



/*Rsuite checkpicker custom css*/

.custom_list_item .rs-picker-list-item{
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: 1px solid #E4E7EC !important;
}

.custom_list_item .rs-stack {
  padding-top: 10px !important; 
  /* padding-bottom: 3px ; */
}

.custom_list_item .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 6px !important;
  margin-top: 5px;
}

.cus_pop.rs-picker-popup{
  width: 170px !important;
  max-height: 320px !important;
}

.rs-checkbox-checker{
  align-items: center;
  display: flex;
}
.rs-check-item .rs-checkbox-checker > label{
  align-items: center !important;
  display: flex;
}

.rs-check-item .rs-checkbox-checker {
  align-items: center !important;
}

label .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #e7e4ec;
  background-color: #e7e4ec;
}

label:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border: none;
}

label .rs-checkbox-wrapper .rs-checkbox-inner {
  border: none;
  background-color: #e7e4ec;
}

.rs-checkbox-wrapper::before {
  background-color: #e7e4ec;
  border-radius: 3px;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #25DAC5 !important;
  background-color: #25DAC5 !important;
  color: #1e0e62 !important;;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #101828 !important;
}


.rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label{
  font-weight: normal !important;
}

.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
  border-color: white !important;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #e5e5ea !important;
}

.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  border-color: white !important;
}

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  outline: none !important;
}
.rs-picker-toggle:focus-visible {
  outline: none !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.rs-stack{
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cus_pop.rs-stack-item {
  max-width: 200px;
}
.cus_pop.rs-picker-toggle-textbox.rs-picker-toggle-read-only{
  max-width: 200px;

  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.cus_pop .rs-picker-value-list {
  max-width: 150px;

  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.rs-picker-value-count{
  display: none;
}

.overlay-content-inv {
  height: 93%;
  z-index: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.overlay-content-inv-pg {
  height: 93%;
  z-index: 0;
  width: -webkit-fill-available;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.overlay-content-invPro {
  height: 100%;
  position: absolute;
  backdrop-filter: blur(4px);
}

.btn_delete_selected {
  cursor: url('/src/Media/curs2.svg'), auto; 
}

.overlay-content-invDetails {
  backdrop-filter: blur(4px);
}

.overlay-content-invDetails-title {
  backdrop-filter: blur(6px);
}

.overlay-content-invDetailsText {
  backdrop-filter: blur(4px);
  
}

.custom-picker button.text-sm:first-letter {
  text-transform: uppercase;
}

.custom-picker button {
  font-style: normal !important;
  font-weight: 400 !important;
}

.custom-picker button.text-sm::after {
  content: "";
  display: none;
}

.custom-picker > div:first-child{
  padding: 5px !important;
  width: 100% !important;
}

.custom-picker > div:first-child > div:first-child >div:first-child{
  padding-left: 1px !important;
  padding-right: 1px !important;
  margin-bottom: 0px !important;
}

.custom-picker > div:first-child >div:last-child >div:first-child >span{
  color: #262D33 !important;
  font-weight: 400 !important;
}

.custom-picker > div:first-child >div:last-child >div:last-child{
  width: 100% !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-size: 0; 
}

.react-calendar__month-view__weekdays__weekday abbr::after {
  content: attr(aria-label); 
  display: inline-block; 
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #1D2939;
}
.react-calendar {
  font-family: 'AvenirNextLTPro' !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #262D33 !important;
}
.react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury {
  color: #D0D5DD !important;
}
.react-calendar__month-view__days__day{
  color: #262D33;
}
.react-calendar__tile{
  border-radius: 8px;
  font-size: 13px !important;
}
.react-calendar__tile--active{
  background-color: #00CDAE !important;
  color: #ffffff !important;
}
.react-calendar__tile--now {
  background-color: #6af0dc !important;
  color: #262D33 !important;
}
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background: #00CDAE !important;
} 

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #00CDAE !important;
}
.react-calendar__navigation__prev-button , .react-calendar__navigation__next-button{
  color: #A9ACB0; 
  font-size: 24px;
}
.react-calendar__navigation{
  margin-bottom: 0px !important;
  padding-left: 2px;
  padding-right: 2px;
}
.react-calendar__navigation__label::first-letter{
  text-transform: uppercase;
}
.react-calendar__navigation__label{
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.react-calendar__month-view__weekdays{
  padding-left: 5px;
  padding-right: 5px;
}
.react-calendar__month-view__days{
 padding-left: 5px;
 padding-right: 5px;
 padding-bottom: 10px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animate_loader {
  width: 69px;
  height: 30px;
  --c: radial-gradient(farthest-side, #a19bf7 90%, #0000);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: animate-loader 1s infinite linear alternate;
}

@keyframes animate-loader {
  0%, 5% {background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%}
  12.5% {background-position: calc(0*100%/3) 0, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%}
  25% {background-position: calc(0*100%/3) 0, calc(1*100%/3) 0, calc(2*100%/3) 50%, calc(3*100%/3) 50%}
  37.5% {background-position: calc(0*100%/3) 100%, calc(1*100%/3) 0, calc(2*100%/3) 0, calc(3*100%/3) 50%}
  50% {background-position: calc(0*100%/3) 100%, calc(1*100%/3) 100%, calc(2*100%/3) 0, calc(3*100%/3) 0}
  62.5% {background-position: calc(0*100%/3) 50%, calc(1*100%/3) 100%, calc(2*100%/3) 100%, calc(3*100%/3) 0}
  75% {background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 100%, calc(3*100%/3) 100%}
  87.5% {background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 100%}
  95%, 100% {background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%}
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.hover\:svg-translate:hover svg {
  transform: translateX(2px);
}

.hover\:stroke-red:hover svg>path {
  stroke: #EA6479;
  transition-property: stroke;
  transition-duration: 300ms;
}

.btnUserProfil:hover >#profileBtn>svg>path {
  stroke: #00CDAE; 
}
.btnUserProfil:hover svg>rect {
  stroke: #00CDAE; 
}

.ReactModal__Content , .ReactModal__Content--after-open {
  border: transparent !important;
  background: transparent !important;
  border-color: transparent !important;
}

.ReactModal__Content:focus , .ReactModal__Content--after-open:focus{
  border: transparent !important;
  background: transparent !important;
  border-color: transparent !important;

}

.popup-content {
  margin: auto;
  background: transparent;
  width: 90%;
  max-width: 640px;
}
/* .popup-arrow {
} */
.creditQuestion-arrow {
  color: #2C3462;
}
/* [role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
} */

.popup-overlay {
  background: #1d2838c1;
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.creditQuestion-content {
  width: 228px;
}

.creditQuestion1-arrow {
  color: #2C3462;
}

.creditQuestion1-content {
  width: 259px;
}

.recharts-tooltip-cursor{
  display: none !important;
}

.custom-link {
  position: relative;
  /* color: #007bff;  */
}

.custom-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; 
  width: 100%;
  height: 1px; 
  background-color: #2575F0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.custom-link:hover::after {
  transform: scaleX(1); /* Creates the underline animation effect */
}