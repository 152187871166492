:root { 
--blue_A400_02:#0e7bff; 
--blue_A400_01:#1877f2; 
--blue_A400:#2575f0; 
--red_400:#ef4352; 
--green_500:#4caf50; 
--light_blue_900:#0e538c; 
--yellow_A200:#faff05; 
--gray_900_0c:#1018280c; 
--green_A700:#1dc86d; 
--blue_gray_900_02:#37363b; 
--blue_gray_900_01:#181f49; 
--deep_purple_A400:#482be7; 
--blue_gray_700:#455c6c; 
--blue_gray_900:#303030; 
--teal_A700:#00cdae; 
--blue_gray_100:#d9d9d9; 
--light_blue_900_01:#006699; 
--blue_700:#1976d2; 
--amber_500:#ffc107; 
--blue_500:#2a94f4; 
--gray_900_66:#15143866; 
--blue_gray_500:#667084; 
--amber_300:#ffd851; 
--blue_50:#edf7ff; 
--gray_100_02:#f4f7f8; 
--gray_100_01:#f7f7f7; 
--blue_300:#6ca6fd; 
--blue_100:#cce9ff; 
--blue_gray_400_01:#8c8c8e; 
--white_A700:#ffffff; 
--blue_gray_700_01:#475466; 
--blue_gray_100_01:#d0d5dd; 
--blue_gray_100_02:#cfd4dc; 
--red_300:#ec7373; 
--black_900_1e:#0000001e; 
--teal_400:#3aada1; 
--black_900:#000000; 
--blue_gray_800:#3c4959; 
--blue_50_01:#dae7fc; 
--deep_orange_A400:#ff3d00; 
--gray_700:#555458; 
--gray_500:#a7a6a8; 
--blue_gray_400:#8f8d95; 
--blue_gray_900_05:#1d2838; 
--blue_gray_900_04:#1f2545; 
--indigo_50:#e4e6eb; 
--gray_900:#1d1c21; 
--blue_gray_900_03:#1d2939; 
--red_300_01:#f97066; 
--gray_900_01:#101828; 
--gray_300:#dcdcdb; 
--blue_gray_800_01:#344053; 
--gray_300_01:#e5e5e6; 
--gray_100:#f2f3f6; 
--teal_A400:#37e288; 
--indigo_900:#203668; 
--blue_100_01:#b2cdf8; 
--rs-checkbox-icon: #1E0E62 !important;
--rs-input-focus-border: #e5e5ea !important;
--rs-color-focus-ring: #e5e5ea !important;
--rs-listbox-option-hover-bg: #f8f9fb !important;
--rs-listbox-option-selected-bg: #f8f9fb !important;
--rs-listbox-option-hover-text: #101828 !important;
}