* {
  box-sizing: border-box;
  line-height: normal;
  outline: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'DMSans-Regular';
}

